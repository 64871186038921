<template>
  <div>
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="客户名称">
            <el-input
              v-model="search.name"
              maxlength="30"
              class="w150"
              clearable
              placeholder="请输入客户名称"
            />
          </el-form-item>
          <el-form-item label="客户手机号" class="ml20">
            <el-input
              v-model="search.phone"
              maxlength="30"
              class="w150"
              clearable
              placeholder="请输入客户手机号"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <el-button
      type="primary"
      v-if="!userInfo.shop_id"
      :disabled="!idList.length"
      class="mb10"
      size="mini"
      @click="hint('导出选中列表')"
    >导出数据</el-button>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
          @selection-change="handleSelectionChange"
        >
          <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
          <ElTableColumn label="客户姓名" prop="nickName" />
          <ElTableColumn label="手机号码" prop="telephone" />
          <ElTableColumn label="性别">
            <template slot-scope="{ row }">
              <div v-if="row.gender == 0">未知</div>
              <div v-if="row.gender == 1">男</div>
              <div v-if="row.gender == 2">女</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="年龄">
            <template slot-scope="{ row }">
              <div>{{row.num?row.num:'未知'}}</div>
            </template>
          </ElTableColumn>

          <ElTableColumn label="是否进店">
            <template slot-scope="{ row }">
              <div>{{ row.enter?row.enter:'未知' }}</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="归属门店">
            <template slot-scope="{ row }">
              <div>{{ row.shop_name?row.shop_name:'未知' }}</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="操作" width="180" fixed="right">
            <template slot-scope="{ row }">
              <span class="theme" v-if="row.customerId" @click="tapBtn('客户详情',row)">查 看</span>
              <span class="theme" v-else @click="tapBtn('客户信息','')">添 加</span>
            </template>
          </ElTableColumn>
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { wxList, wxExport } from "@/api/sys";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ""; // 客户名称
  phone = ""; // 客户电话
}

export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(), // 分页
      search: new Search(), // 检索条件
      list: [], // 数据展示
      idList: ""
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      wxList({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 按钮
    tapBtn(routerName, row) {

      // const query = {
      //   id: row.id,
      //   activeName: "first",
      //   customer_name: row.customer_name
      // };
      var query={}
      if(routerName == "客户详情")
      {
          query = {
            id: row.customerId,
            activeName: "first",
            customer_name: row.customer_name
          };
      }else{
         query = {
           id: row.id,
           activeName: "first",
           customer_name: row.customer_name
         };
      }
      this.$router.push({ name: routerName, query });
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      wxExport({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },

    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
