var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"bgW"},[_c('el-col',{attrs:{"span":22}},[_c('el-form',{staticClass:"search",attrs:{"inline":true,"label-suffix":"：","size":"mini","model":_vm.search}},[_c('el-form-item',{attrs:{"label":"客户名称"}},[_c('el-input',{staticClass:"w150",attrs:{"maxlength":"30","clearable":"","placeholder":"请输入客户名称"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('el-form-item',{staticClass:"ml20",attrs:{"label":"客户手机号"}},[_c('el-input',{staticClass:"w150",attrs:{"maxlength":"30","clearable":"","placeholder":"请输入客户手机号"},model:{value:(_vm.search.phone),callback:function ($$v) {_vm.$set(_vm.search, "phone", $$v)},expression:"search.phone"}})],1)],1)],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"mini"},on:{"click":function($event){return _vm.getList('search')}}},[_vm._v("搜索")])],1)],1),(!_vm.userInfo.shop_id)?_c('el-button',{staticClass:"mb10",attrs:{"type":"primary","disabled":!_vm.idList.length,"size":"mini"},on:{"click":function($event){return _vm.hint('导出选中列表')}}},[_vm._v("导出数据")]):_vm._e(),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{attrs:{"data":_vm.list,"size":"small","border":"","cell-style":_vm.$style.cellStyle,"header-cell-style":_vm.$style.rowClass},on:{"selection-change":_vm.handleSelectionChange}},[(!_vm.userInfo.shop_id)?_c('ElTableColumn',{attrs:{"type":"selection","width":"50"}}):_vm._e(),_c('ElTableColumn',{attrs:{"label":"客户姓名","prop":"nickName"}}),_c('ElTableColumn',{attrs:{"label":"手机号码","prop":"telephone"}}),_c('ElTableColumn',{attrs:{"label":"性别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.gender == 0)?_c('div',[_vm._v("未知")]):_vm._e(),(row.gender == 1)?_c('div',[_vm._v("男")]):_vm._e(),(row.gender == 2)?_c('div',[_vm._v("女")]):_vm._e()]}}])}),_c('ElTableColumn',{attrs:{"label":"年龄"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.num?row.num:'未知'))])]}}])}),_c('ElTableColumn',{attrs:{"label":"是否进店"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.enter?row.enter:'未知'))])]}}])}),_c('ElTableColumn',{attrs:{"label":"归属门店"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.shop_name?row.shop_name:'未知'))])]}}])}),_c('ElTableColumn',{attrs:{"label":"操作","width":"180","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.customerId)?_c('span',{staticClass:"theme",on:{"click":function($event){return _vm.tapBtn('客户详情',row)}}},[_vm._v("查 看")]):_c('span',{staticClass:"theme",on:{"click":function($event){return _vm.tapBtn('客户信息','')}}},[_vm._v("添 加")])]}}])})],1),_c('pagination',{staticClass:"page tc mt10",attrs:{"total":_vm.page.total,"page":_vm.page.page,"limit":_vm.page.limit},on:{"update:page":function($event){return _vm.$set(_vm.page, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.page, "limit", $event)},"pagination":_vm.getList}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }